<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header
        :entity="$EntitiesName.AdministrationStatus"
        :create="openCreateModal"
      >
        <h1 class="title">Statuts de gestion</h1>
      </page-header>

      <datatable
        ref="administrationStatusList"
        :fetch="$Api.AdministrationStatus.fetchAdministrationStatuses"
        :enableDownload="true"
        :entity="$EntitiesName.AdministrationStatus"
        downloadEntity="administration-statuses"
      >
        <div slot="name" slot-scope="{ item }" title="Nom" :sortable="true">{{ item.name }}</div>
      </datatable>
    </div>

    <modal
      ref="createAdministrationStatusModal"
      @create="createAdministrationStatus"
      title="Création d'un statut de gestion"
    >
      <div class="columns">
        <div class="column is-half">
        <ValidationObserver ref="form">
          <text-field
            label="Nom"
            data-test="sel-administrationstatus-name"
            v-model="administrationStatus.name"
            :inline="false"
            :edit="true"
            required
          />
        </ValidationObserver>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "administrationStatus",
  data() {
    return {
      administrationStatus: {},
      columns: {
        name: {
          title: "Nom",
        },
      },
    };
  },
  methods: {
    openCreateModal() {
      this.$refs.createAdministrationStatusModal.open();
    },
    createAdministrationStatus() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }
        axios.post("/administration-statuses", this.administrationStatus).then((response) => {
          if (response.data.success) {
            this.administrationStatus = {
              id: "",
              name: "",
            };
            this.$refs.administrationStatusList.refresh();
            this.$refs.createAdministrationStatusModal.close();
            this.$router.push({
              name: "AdministrationStatus",
              params: { id: response.data.id },
            });
          }
        });
      })
    },
  },
};
</script>

<style scoped>
.infos {
  display: inline-block;
}

.infos {
  width: 34%;
}
</style>